import React, { Fragment, useEffect, useState } from "react";
import { Input, MainContent, Select } from "../../../components";
import { Link, useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import { FormatPrice, getElementData } from "../../../constances";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import {
  MdOutlineAssessment,
  MdPendingActions,
  MdOutlinePlayCircle,
  MdOutlineCheckCircle,
} from "react-icons/md";
import {
  exportToExcel,
  exportToPDF,
  getObservation,
  normalizeString,
} from "../../../constances/functions";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ReportingView = () => {
  const { id_unite_gestion } = useParams();
  const {
    unites_gestions,
    activites,
    ptba,
    categorieDepenses,
    composantes,
    sousComposantes,
    sousActivites,
  } = usePlateformContext();

  let unite_gestion = getElementData(id_unite_gestion, unites_gestions);

  let composantes_data = composantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let ptba_data = ptba.filter(
    (item) => item.unite_gestion.toString() === id_unite_gestion
  );
  let categorie_depenses_data = categorieDepenses.filter(
    (item) => item.unite_gestion.toString() === id_unite_gestion
  );
  let sous_composante_data = sousComposantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  let activites_data = activites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let sous_activites_data = sousActivites.filter(
    (item) => item.unite_gestion.toString() === id_unite_gestion
  );

  // Filtre options
  let model_data = {
    ptba: "",
    categorie_depenses: "",
    composante: "",
    nature: "",
    query: "",
    status: "",
    observation: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const [optionsPTBA, setOptionsPTBA] = useState([]);
  const [optionsCategorie, setOptionsCategorie] = useState([]);
  const [optionsComposante, setOptionsComposante] = useState([]);

  useEffect(() => {
    let options = [];

    ptba_data.map((item) => {
      options.push({
        value: item.id,
        label: `Activité du PTBA - ${item.annee}`,
      });
    });

    setOptionsPTBA(options);
  }, [ptba]);

  useEffect(() => {
    let options = [];

    categorie_depenses_data.map((item) => {
      options.push({ value: item.id, label: `${item.designation}` });
    });

    setOptionsCategorie(options);
  }, [categorieDepenses]);

  useEffect(() => {
    let options = [];

    composantes_data.map((item) => {
      options.push({ value: item.id, label: `${item.designation}` });
    });

    setOptionsComposante(options);
  }, [composantes]);

  // Filtre activites
  function filterActivites(activites_data, credentials) {
    let data = credentials.ptba
      ? ptba_data.filter(
          (item) => item.id.toString() === credentials.ptba.toString()
        )[0]?.sous_activites
      : activites_data;

    return data.filter(
      (item) =>
        (credentials.nature ? item.nature === credentials.nature : true) &&
        (credentials.categorie_depenses
          ? item.categorie_depense.toString() === credentials.categorie_depenses
          : true) &&
        (credentials.composante
          ? item.composante.toString() === credentials.composante
          : true)
    );
  }

  let filteredActivites = filterActivites(sous_activites_data, credentials)
    .filter((item) => {
      let evolutions_all = item?.evolutions_sous_activite?.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      let evolutions_validated = evolutions_all?.filter((e) => e.validated);

      let evolutions_last = evolutions_validated
        ? evolutions_validated[0]
        : {
            montant_realisation: 0,
            cumul_realisation: 0,
            status: "",
          };

      if (!credentials.status) {
        return true;
      } else if (credentials.status === "non demarre") {
        return !evolutions_last?.status;
      } else {
        return evolutions_last?.status === credentials.status;
      }
    })
    .filter(
      (item) =>
        normalizeString(item.designation).includes(
          `${credentials.query}`.toLowerCase()
        ) ||
        normalizeString(item.indicateur_resultat).includes(
          `${credentials.query}`.toLowerCase()
        )
    )
    .filter((item) => {
      const check_observation = !credentials.observation
        ? true
        : getObservation(item.date_debut, item.date_fin).value ===
          credentials.observation;

      return check_observation;
    });

  let data_realisation = {
    labels: filteredActivites.map((item, index) => `${item.get_reference}`),
    datasets: [
      {
        label: "Exécution physique",
        backgroundColor: ["#0D7D6F", "#264653"],
        borderColor: ["#0D7D6F", "#264653"],
        borderWidth: 0,
        data: filteredActivites.map((item) => {
          let evolutions_all = item?.evolutions_sous_activite?.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          );
          let evolutions_validated = evolutions_all?.filter((e) => e.validated);

          let evolutions_last = evolutions_validated
            ? evolutions_validated[0]
            : {
                montant_realisation: 0,
                cumul_realisation: 0,
              };

          // Calcule Execution physique
          const cumul_realisation = evolutions_last?.cumul_realisation || 0;
          const cumul_realisation_pourcentage = parseFloat(
            (cumul_realisation / item.indicateur_cible) * 100
          ).toFixed(0);

          return cumul_realisation_pourcentage;
        }),
      },
    ],
  };

  let data_budget = {
    labels: filteredActivites.map((item, index) => `${item.get_reference}`),
    datasets: [
      {
        label: "Exécution financière",
        backgroundColor: ["#F4A261", "#E76F51"],
        borderColor: ["#F4A261", "#E76F51"],
        borderWidth: 0,
        data: filteredActivites.map((item) => {
          let evolutions_all = item?.evolutions_sous_activite?.sort(
            (a, b) => new Date(b.created) - new Date(a.created)
          );
          let evolutions_validated = evolutions_all?.filter((e) => e.validated);

          let evolutions_last = evolutions_validated
            ? evolutions_validated[0]
            : {
                montant_realisation: 0,
                cumul_realisation: 0,
              };

          // Calcule Execution financiere
          const montant_realisation = evolutions_last?.montant_realisation || 0;
          const montant_realisation_pourcentage = parseFloat(
            (montant_realisation / item.montant) * 100
          ).toFixed(0);

          return montant_realisation_pourcentage;
        }),
      },
    ],
  };

  let somme_activites_devise_locale = 0;
  let somme_activites_devise_bayeur = 0;

  let somme_realisation_activites_devise_locale = 0;
  let somme_realisation_activites_devise_bayeur = 0;

  filteredActivites.map((item) => {
    somme_activites_devise_locale =
      somme_activites_devise_locale + item.montant;
    somme_activites_devise_bayeur =
      somme_activites_devise_bayeur +
      item.montant / unite_gestion?.valeur_devise;

    // Calcule des montant de realisation
    let evolutions_all = item?.evolutions_sous_activite?.sort(
      (a, b) => new Date(b.created) - new Date(a.created)
    );
    let evolutions_validated = evolutions_all?.filter((e) => e.validated);
    let evolutions_last = evolutions_validated
      ? evolutions_validated[0]
      : {
          montant_realisation: 0,
          cumul_realisation: 0,
        };

    // Calcule Execution financiere
    const montant_realisation = evolutions_last?.montant_realisation || 0;

    somme_realisation_activites_devise_locale += montant_realisation;
    somme_realisation_activites_devise_bayeur +=
      montant_realisation / unite_gestion?.valeur_devise;
  });

  let total_categorie = 0;
  let total_categorie_devise = 0;

  if (credentials.categorie_depenses) {
    let categorie = getElementData(
      credentials?.categorie_depenses,
      categorie_depenses_data
    );

    total_categorie = parseInt(categorie?.montant_devise_locale);
    total_categorie_devise = parseFloat(categorie?.montant_devise).toFixed(2);
  } else {
    categorie_depenses_data.map((item) => {
      total_categorie = total_categorie + parseInt(item.montant_devise_locale);
      total_categorie_devise =
        total_categorie_devise +
        parseInt(parseFloat(item.montant_devise).toFixed(0));
    });
  }

  let optionsNature = [
    { value: "Investissement", label: "Investissement" },
    { value: "Fonctionnement", label: "Fonctionnement" },
  ];

  const getFilterActivites = (ativites = [], status) => {
    return ativites?.filter((item) => {
      let evolutions_all = item?.evolutions_sous_activite?.sort(
        (a, b) => new Date(b.created) - new Date(a.created)
      );
      let evolutions_validated = evolutions_all?.filter((e) => e.validated);
      let evolutions_last = evolutions_validated
        ? evolutions_validated[0]
        : {
            montant_realisation: 0,
            cumul_realisation: 0,
            status: "",
          };

      return evolutions_last?.status === status;
    });
  };

  let optionsStatuts = [
    { value: "non demarre", label: "Non demarrée" },
    { value: "en cours", label: "En cours" },
    { value: "termine", label: "Terminé" },
  ];

  let optionsObservation = [
    { value: "upcoming", label: "À venir" },
    { value: "on_time", label: "Dans les délais" },
    { value: "late", label: "En retard" },
  ];

  const stats = [
    {
      id: 1,
      icon: <MdOutlineAssessment className="text-blue-500 text-3xl" />,
      count: filteredActivites.length,
      label: "Total Activités",
    },
    {
      id: 2,
      icon: <MdPendingActions className="text-yellow-500 text-3xl" />,
      count: getFilterActivites(filteredActivites, "en attente").length,
      label: "Activités en attente",
    },
    {
      id: 3,
      icon: <MdOutlinePlayCircle className="text-orange-500 text-3xl" />,
      count: getFilterActivites(filteredActivites, "en cours").length,
      label: "Activités en cours",
    },
    {
      id: 4,
      icon: <MdOutlineCheckCircle className="text-green-500 text-3xl" />,
      count: getFilterActivites(filteredActivites, "termine").length,
      label: "Activités terminées",
    },
  ];

  return (
    <MainContent title={`Rapport d'activités`}>
      <div className={`flex flex-col justify-center items-center`}>
        <div className="container flex flex-col flex-grow">
          <div className="flex items-center w-full md:flex-row flex-col py-4">
            <Select
              label={"Programme de Travail et Budget Annuel (PTBA)"}
              placeholder={"Toutes les activités"}
              autoComplete="off"
              value={credentials?.ptba}
              error={credentialsError.ptba}
              onChange={handleChange}
              name={"ptba"}
              className={"md:w-[25%] w-[100%] mb-2"}
              options={optionsPTBA}
            />

            <Select
              label={"Nature d'activité"}
              placeholder={"Toutes les natures"}
              autoComplete="off"
              value={credentials?.nature}
              error={credentialsError.nature}
              onChange={handleChange}
              name={"nature"}
              className={"md:w-[25%] w-[100%] mb-2"}
              options={optionsNature}
            />

            <Select
              label={"Categorie de depenses"}
              placeholder={"Toutes les categories"}
              autoComplete="off"
              value={credentials?.categorie_depenses}
              error={credentialsError.categorie_depenses}
              onChange={handleChange}
              name={"categorie_depenses"}
              className={"md:w-[25%] w-[100%] mb-2"}
              options={optionsCategorie}
            />

            <Select
              label={"Composante"}
              placeholder={"Toutes les composantes"}
              autoComplete="off"
              value={credentials?.composante}
              error={credentialsError.composante}
              onChange={handleChange}
              name={"composante"}
              className={"md:w-[25%] w-[100%] mb-2"}
              options={optionsComposante}
            />
          </div>

          <div className="mb-6">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {stats.map((stat) => (
                <div
                  key={stat.id}
                  className="flex items-center p-4 bg-white rounded-lg"
                >
                  <div className="mr-4">{stat.icon}</div>
                  <div>
                    <h1 className="font-bold text-2xl">{stat.count}</h1>
                    <p className="text-md text-gray-600">{stat.label}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col md:flex-row w-full md:items-start md:justify-between">
            <div className="flex-grow w-[100%] md:w-[75%] mb-4 flex flex-col md:flex-row md:justify-between items-center">
              <div className="md:w-[49.5%] mb-4 md:mb-0 w-[100%] md:h-[250px] border-[1px] p-2 border-gray-400 rounded-md">
                <Bar
                  data={data_budget}
                  options={{}}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>

              <div className="md:w-[49.5%] w-[100%] mb-0 md:h-[250px] border-[1px] p-2 border-gray-400 rounded-md">
                <Bar
                  data={data_realisation}
                  options={{}}
                  style={{ width: "100%", height: "100%" }}
                />
              </div>
            </div>

            <div className="flex-grow w-[100%] md:pl-3 md:w-[25%] mb-4">
              <div className="table-container">
                <table className="table">
                  <thead className="bg-gray-600 text-light">
                    <tr>
                      <th colSpan={2} className="text-center">
                        Montant du projet
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-center w-[50%]">
                        {FormatPrice(unite_gestion?.cout_total)} FCFA
                      </td>
                      <td className="text-center w-[50%]">
                        {FormatPrice(
                          parseFloat(unite_gestion?.cout_total_devise).toFixed(
                            0
                          )
                        )}{" "}
                        {unite_gestion?.devise}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <div className="table-container">
                <table className="table my-3">
                  <thead className="bg-gray-600 text-light">
                    <tr>
                      <th colSpan={2} className="text-center">
                        Budget categorie de depenses
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-center w-[50%]">
                        {FormatPrice(total_categorie)} FCFA
                      </td>
                      <td className="text-center w-[50%]">
                        {FormatPrice(
                          parseFloat(total_categorie_devise).toFixed(0)
                        )}{" "}
                        {unite_gestion?.devise}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> */}

              <div className="table-container mt-3">
                <table className="table">
                  <thead className="bg-gray-600 text-light">
                    <tr>
                      <th colSpan={2} className="text-center">
                        Montant total des activités
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-center w-[50%]">
                        {FormatPrice(somme_activites_devise_locale)} FCFA
                      </td>
                      <td className="text-center w-[50%]">
                        {FormatPrice(
                          parseFloat(somme_activites_devise_bayeur).toFixed(0)
                        )}{" "}
                        {unite_gestion?.devise}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div className="table-container mt-3">
                <table className="table">
                  <thead className="bg-gray-600 text-light">
                    <tr>
                      <th colSpan={2} className="text-center">
                        Montant total des réalisations
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td className="text-center w-[50%]">
                        {FormatPrice(somme_realisation_activites_devise_locale)}{" "}
                        FCFA
                      </td>
                      <td className="text-center w-[50%]">
                        {FormatPrice(
                          parseFloat(
                            somme_realisation_activites_devise_bayeur
                          ).toFixed(0)
                        )}{" "}
                        {unite_gestion?.devise}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between w-full md:flex-row flex-col py-4">
            <div className="flex md:items-center md:w-[60%] w-[100%] md:flex-row flex-col mb-2">
              <Select
                type={"text"}
                required={false}
                placeholder={"Filtrer / Statut"}
                autoComplete="off"
                value={credentials?.status}
                error={credentialsError.status}
                onChange={handleChange}
                name={"status"}
                options={optionsStatuts}
                className={"md:w-[35%] w-[100%]"}
              />
              <Select
                type={"text"}
                required={false}
                placeholder={"Filtrer / Observation"}
                autoComplete="off"
                value={credentials?.observation}
                error={credentialsError.observation}
                onChange={handleChange}
                name={"observation"}
                options={optionsObservation}
                className={"md:w-[35%] w-[100%]"}
              />
              <Input
                type={"text"}
                placeholder={"Recherche ..."}
                value={credentials?.query}
                error={credentialsError.query}
                onChange={handleChange}
                name={"query"}
                className={"md:w-[30%] w-[100%]"}
              />
            </div>

            <div className="flex space-x-2">
              <button
                onClick={() =>
                  exportToExcel(`EXECUTION PHYSIQUE ET FINANCIERE`)
                }
                className="bg-green-500 text-white px-4 py-2 rounded text-sm"
              >
                Exporter en Excel
              </button>
              <button
                onClick={() => exportToPDF(`EXECUTION PHYSIQUE ET FINANCIERE`)}
                className="bg-red-500 text-white px-4 py-2 rounded text-sm"
              >
                Exporter en PDF
              </button>
            </div>
          </div>

          <div className="table-container mb-5">
            <table className="table table-export-excel table-export-pdf">
              <thead>
                <tr className="bg-dark text-light">
                  <th colSpan={2}>Composante/Résultat</th>
                  <th colSpan={5}>Exécution physique</th>
                  <th colSpan={3}>Exécution financière</th>
                  <th colSpan={1}>Observation</th>
                </tr>
                <tr style={{ backgroundColor: "#C5E0B3" }}>
                  <th colSpan={2}>Sous-composante ou produit</th>

                  <th>Indicateur</th>
                  <th>Unité</th>
                  <th>Cible</th>
                  <th>Cumul</th>
                  <th>%</th>

                  <th style={{ color: "#FF0000" }}>Montant prévu (FCFA)</th>
                  <th style={{ color: "#FF0000" }}>Réalisation (FCFA)</th>
                  <th style={{ color: "#FF0000" }}>%</th>
                  <th className="text-center">-</th>
                </tr>
                <tr>
                  <th colSpan={11}></th>
                </tr>
              </thead>

              <tbody>
                {composantes_data.map((composante) => {
                  const sousComposantes = sous_composante_data.filter(
                    (item) => item.composante === composante.id
                  );

                  const hasActivites = filteredActivites.some(
                    (item) => item.composante === composante.id
                  );

                  if (sousComposantes.length > 0 && hasActivites) {
                    return (
                      <Fragment key={composante.id}>
                        <tr style={{ backgroundColor: "#FFC000" }}>
                          <td width={50}>{composante.reference}</td>
                          <td colSpan={9} className="whitespace-normal">
                            {composante.designation}
                          </td>
                          <td className="text-center">-</td>
                        </tr>

                        {sousComposantes.map((sousComposante) => {
                          const activitesFiltered = activites_data.filter(
                            (item) =>
                              item.composante === composante.id &&
                              item.sous_composante === sousComposante.id
                          );

                          const hasSousActivites = filteredActivites.some(
                            (item) =>
                              item.composante === composante.id &&
                              item.sous_composante === sousComposante.id
                          );

                          if (
                            activitesFiltered.length > 0 &&
                            hasSousActivites
                          ) {
                            return (
                              <Fragment key={sousComposante.id}>
                                <tr style={{ backgroundColor: "#FFF2CC" }}>
                                  <td width={50}>{sousComposante.reference}</td>
                                  <td colSpan={9} className="whitespace-normal">
                                    {sousComposante.designation}
                                  </td>
                                  <td className="text-center">-</td>
                                </tr>

                                {activitesFiltered.map((activite) => {
                                  const sousActivites =
                                    filteredActivites.filter(
                                      (item) =>
                                        item.composante === composante.id &&
                                        item.sous_composante ===
                                          sousComposante.id &&
                                        item.activite === activite.id
                                    );

                                  if (sousActivites.length > 0) {
                                    return (
                                      <Fragment key={activite.id}>
                                        <tr
                                          style={{ backgroundColor: "#E7E6E6" }}
                                        >
                                          <td width={50}>
                                            {activite.reference}
                                          </td>
                                          <td
                                            colSpan={9}
                                            className="whitespace-normal"
                                          >
                                            {activite.designation}
                                          </td>
                                          <td className="text-center">-</td>
                                        </tr>

                                        {sousActivites.map((sousActivite) => {
                                          const evolutions =
                                            sousActivite.evolutions_sous_activite
                                              ?.filter((e) => e.validated)
                                              .sort(
                                                (a, b) =>
                                                  new Date(b.created) -
                                                  new Date(a.created)
                                              );

                                          const lastEvolution =
                                            evolutions?.[0] || {
                                              montant_realisation: 0,
                                              cumul_realisation: 0,
                                            };

                                          const montantRealisation =
                                            lastEvolution.montant_realisation ||
                                            0;
                                          const montantRealisationPercentage =
                                            Math.round(
                                              (montantRealisation /
                                                sousActivite.montant) *
                                                100
                                            );

                                          const cumulRealisation =
                                            lastEvolution.cumul_realisation ||
                                            0;
                                          const cumulRealisationPercentage =
                                            Math.round(
                                              (cumulRealisation /
                                                sousActivite.indicateur_cible) *
                                                100
                                            ) || 0;

                                          return (
                                            <tr
                                              key={sousActivite.id}
                                              style={{
                                                backgroundColor: "#f7f7f7",
                                              }}
                                            >
                                              <td width={50}>
                                                {sousActivite.reference}
                                              </td>
                                              <td className="whitespace-normal">
                                                <Link
                                                  to={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${sousActivite.id}`}
                                                  className="text-dark font-semibold"
                                                >
                                                  {sousActivite.designation}
                                                </Link>
                                              </td>

                                              <td>
                                                {
                                                  sousActivite.indicateur_resultat
                                                }
                                              </td>
                                              <td>
                                                {sousActivite.indicateur_unite}
                                              </td>
                                              <td>
                                                {sousActivite.indicateur_cible}
                                              </td>
                                              <td>{cumulRealisation}</td>
                                              <td>
                                                {cumulRealisationPercentage}
                                              </td>

                                              <td style={{ color: "#FF0000" }}>
                                                {FormatPrice(
                                                  sousActivite.montant
                                                )}
                                              </td>
                                              <td style={{ color: "#FF0000" }}>
                                                {FormatPrice(
                                                  montantRealisation
                                                )}
                                              </td>
                                              <td style={{ color: "#FF0000" }}>
                                                {montantRealisationPercentage || 0}
                                              </td>

                                              <td className="uppercase">
                                                {
                                                  getObservation(
                                                    sousActivite.date_debut,
                                                    sousActivite.date_fin
                                                  ).label
                                                }
                                              </td>
                                            </tr>
                                          );
                                        })}
                                      </Fragment>
                                    );
                                  }
                                  return null;
                                })}
                              </Fragment>
                            );
                          }
                          return null;
                        })}
                      </Fragment>
                    );
                  }
                  return null;
                })}

                {!filteredActivites.length && (
                  <tr>
                    <td colSpan={11} className="h-20 text-center">
                      Aucune activité
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default ReportingView;
