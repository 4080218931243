import React from "react";
import { Button, Input, MainContent, Select } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import { getElementData } from "../../../constances";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

const ActiviteForm = () => {
  const navigate = useNavigate();
  const { id_activite, id_unite_gestion } = useParams();
  const {
    composantes,
    sousComposantes,
    voletsActivites,
    user,
    update_activites,
    create_activites,
    delete_activites,
    activites,
  } = usePlateformContext();

  let check_activite = id_activite === "nouvelle";

  let activite = getElementData(id_activite, activites);
  let composantes_data = composantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let sousComposantes_data = sousComposantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let volet_activite_data = voletsActivites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  let model_data = {
    composante: "",
    sous_composante: "",
    designation: "",
    volet_activite: "",
    reference: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleChangeError = (name, value) => {
    setCredentialsError({ ...credentialsError, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation du formulaire

    const errors = {}; // Objet pour stocker les erreurs

    if (!credentials.reference) {
      errors.reference = "Entrez la référence";
    }

    if (!credentials.designation) {
      errors.designation = "Entrez la designation";
    }

    if (!credentials.sous_composante) {
      errors.sous_composante = "Selectionner une sous composante";
    }

    // if (!credentials.volet_activite) {
    //   errors.volet_activite = "Selectionner le volet d'activité";
    // }

    if (Object.keys(errors).length > 0) {
      setCredentialsError(errors);
    } else {
      let response = !check_activite
        ? await update_activites(credentials)
        : await create_activites(credentials);

      if (response) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    setCredentials(check_activite ? model_data : activite);
  }, [sousComposantes]);

  // Options composantes
  const [optionsComposantes, setOptionsComposantes] = useState([]);
  const [optionsSousComposantes, setOptionsSousComposantes] = useState([]);
  const [optionsVoletActivites, setOptionsVoletActivites] = useState([]);

  useEffect(() => {
    let liste_composantes = [];

    composantes_data.map((item) => {
      return liste_composantes.push({
        value: item.id,
        label: `${item.reference}: ${item.designation}`,
      });
    });

    setOptionsComposantes(liste_composantes);
  }, [composantes_data]);

  useEffect(() => {
    // let sous_comp = getElementData(credentials.sous_composante, sousComposantes)

    // if (sous_comp && sous_comp?.composante?.toString() !== credentials.composante) {
    //     setCredentials({...credentials, ["sous_composante"] : ""});
    // }

    let liste_sous_composante = [];

    sousComposantes_data
      .filter(
        (item) =>
          item.composante.toString() === credentials?.composante.toString()
      )
      .map((item) => {
        return liste_sous_composante.push({
          value: item.id,
          label: `${item.reference}: ${item.designation}`,
        });
      });

    setOptionsSousComposantes(liste_sous_composante);
  }, [credentials]);

  useEffect(() => {
    // let sous_comp = getElementData(credentials.sous_composante, sousComposantes)

    // if (sous_comp && sous_comp?.composante?.toString() !== credentials.composante) {
    //     setCredentials({...credentials, ["sous_composante"] : ""});
    // }

    let liste_volet_activite = [];

    volet_activite_data
      .filter(
        (item) =>
          item.sous_composante.toString() ===
          credentials?.sous_composante.toString()
      )
      .map((item) => {
        return liste_volet_activite.push({
          value: item.id,
          label: `${item.designation}`,
        });
      });

    setOptionsVoletActivites(liste_volet_activite);
  }, [credentials]);

  // console.log(optionsSousComposantes)

  return (
    <MainContent
      title={`${check_activite ? "Créer une activité" : activite?.designation}`}
    >
      <div className="flex items-center mt-5 justify-between px-2 md:px-1 py-4">
        <div className="xl:max-w-[70%] max-w-[50%]">
          <h1 className="font-bold text-lg">
            {check_activite ? "Nouvelle activité" : "Modifier l'activité"}
          </h1>
          <p className="text-md font-light">
            {check_activite
              ? "Enregistrer une nouvelle activité pour le projet"
              : `Modifier l'activité "${activite?.designation}"`}
          </p>
        </div>

        <div className="flex items-center"></div>
      </div>

      <div className="flex flex-col justify-start items-center py-3">
        <form
          onSubmit={handleSubmit}
          className="w-[700px] max-w-[95%] bg-white p-3 rounded-md"
        >
          <div>
            <Select
              label={"Composante"}
              required={true}
              placeholder={"Selectionner la composante"}
              autoComplete="off"
              value={credentials?.composante}
              error={credentialsError.composante}
              onChange={handleChange}
              onFocus={() => handleChangeError("composante", null)}
              name={"composante"}
              options={optionsComposantes}
            />

            <Select
              label={"Sous-composante"}
              required={true}
              placeholder={"Selectionner la sous-composante"}
              autoComplete="off"
              value={credentials?.sous_composante}
              error={credentialsError.sous_composante}
              onChange={handleChange}
              onFocus={() => handleChangeError("sous_composante", null)}
              name={"sous_composante"}
              options={optionsSousComposantes}
            />

            <Select
              label={"Volet d'activité"}
              placeholder={"Selectionner le volet d'activité"}
              autoComplete="off"
              value={credentials?.volet_activite}
              error={credentialsError.volet_activite}
              onChange={handleChange}
              onFocus={() => handleChangeError("volet_activite", null)}
              name={"volet_activite"}
              options={optionsVoletActivites}
              className="mt-2"
            />

            <Input
              type={"text"}
              label={"Référence"}
              required={true}
              placeholder={"Ex: A.1.1"}
              autoComplete="off"
              value={credentials?.reference}
              error={credentialsError.reference}
              onChange={handleChange}
              onFocus={() => handleChangeError("reference", null)}
              name={"reference"}
            />

            <Input
              type={"text"}
              label={"Designation"}
              required={true}
              placeholder={"Designation"}
              autoComplete="off"
              value={credentials?.designation}
              error={credentialsError.designation}
              onChange={handleChange}
              onFocus={() => handleChangeError("designation", null)}
              name={"designation"}
            />

            <Button
              type="submit"
              text={"Enregistrer"}
              bgColor={"bg-color-plt-1"}
            />
          </div>
        </form>

        {!check_activite && (
          <div className="w-[700px] max-w-[95%] bg-white p-3 rounded-md">
            <button
              onClick={() => {
                delete_activites(activite);
                navigate(-1);
              }}
              className="text-color-plt-6 flex items-center text-[12px]"
            >
              <AiOutlineDelete />
              <span className="ml-1">Supprimer</span>
              <span className="ml-1 text-dark">(non recommandé)</span>
            </button>
          </div>
        )}
      </div>
    </MainContent>
  );
};

export default ActiviteForm;
