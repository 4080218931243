import React from "react";
import { EvolutionTimeLine, LinkBtn, MainContent } from "../../../components";
import {
  FormatPrice,
  check_admin,
  check_admin_composante,
  getElementData,
  obtenirPeriode,
} from "../../../constances";
import { Link, useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import {
  AiFillEdit,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlinePlusCircle,
} from "react-icons/ai";

const SousActiviteDetails = () => {
  const { id_unite_gestion, id_sous_activite } = useParams();
  const {
    composantes,
    sousComposantes,
    voletsActivites,
    user,
    sousActivites,
    unites_gestions,
    categorieDepenses,
    activites,
    create_validation_tache,
  } = usePlateformContext();

  let unite_gestion = getElementData(id_unite_gestion, unites_gestions);
  let sous_activite = getElementData(id_sous_activite, sousActivites);
  let composantes_data = composantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let sousComposantes_data = sousComposantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let voletsActivites_data = voletsActivites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  let evolutions_all = sous_activite?.evolutions_sous_activite?.sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  );
  let evolutions_validated = evolutions_all?.filter((e) => e.validated);
  let evolutions_last = evolutions_validated
    ? evolutions_validated[0]
    : {
        montant_realisation: 0,
        cumul_realisation: 0,
      };

  const hundleValidation = async (evolution, value) => {
    await create_validation_tache({
      evolution: evolution,
      user: user?.id,
      validated: value,
    });
  };

  return (
    <MainContent title={`Retour`}>
      <div className="flex items-center mt-5 justify-between md:px-1 py-4">
        <div className="xl:max-w-[70%] max-w-[50%]">
          <h1 className="font-bold text-lg">
            Sous-activité n° {sous_activite?.reference}
          </h1>
          {/* <p className='text-md font-light'>Liste des sous-activités enregistrées pour le projet</p> */}
        </div>

        <div className="flex items-center">
          {check_admin(user) && (
            <LinkBtn
              description={"Modifer"}
              icon={<AiOutlineEdit />}
              path={`/unites-gestions/${id_unite_gestion}/sous-activites/${id_sous_activite}`}
              className={
                "bg-color-plt-1 text-light md:w-auto w-[100%] mb-2 md:mb-0"
              }
            />
          )}
        </div>
      </div>

      <div className="bg-white rounded-lg p-2 mb-3">
        <h1 className="font-medium">Suivit et évaluation</h1>
        <p className="text-sm font-light">
          Suivit et évaluation de l'exécution physique et financière
        </p>
      </div>

      <div
        className={`flex xl:flex-row flex-col-reverse items-start xl:justify-between mb-10`}
      >
        <div className="xl:w-[400px] w-[100%] xl:mb-0 mb-4">
          <div className="bg-white rounded-lg p-2 mb-3">
            <div>
              <h1 className="font-medium">
                Evaluation de l'exécution financière
              </h1>
            </div>

            <div className="my-3">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      Montant prévu
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold text-sm text-color-plt-1 text-center">
                      {FormatPrice(sous_activite?.montant)} {"FCFA"}
                    </td>
                    <td className="font-bold text-sm text-color-plt-1 text-center">
                      {FormatPrice(
                        parseFloat(
                          sous_activite?.montant / unite_gestion?.valeur_devise
                        ).toFixed(0)
                      )}{" "}
                      {unite_gestion?.devise}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="my-3">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      Montant Réalisation
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold text-sm text-color-plt-1 text-center">
                      {FormatPrice(evolutions_last?.montant_realisation || 0)}{" "}
                      {"FCFA"}
                    </td>
                    <td className="font-bold text-sm text-color-plt-1 text-center">
                      {FormatPrice(
                        evolutions_last?.montant_realisation
                          ? parseFloat(
                              evolutions_last?.montant_realisation /
                                unite_gestion?.valeur_devise
                            ).toFixed(0)
                          : 0
                      )}{" "}
                      {unite_gestion?.devise}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pb-2">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      % de réalisation financière
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="font-bold text-sm text-color-plt-1 text-center"
                    >
                      <EvolutionTimeLine
                        value={
                          evolutions_last?.montant_realisation
                            ? parseFloat(
                                (evolutions_last?.montant_realisation /
                                  sous_activite?.montant) *
                                  100
                              ).toFixed(0)
                            : 0
                        }
                        width={`100%`}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="bg-white rounded-lg p-2">
            <div>
              <h1 className="font-medium">
                Evaluation de l'exécution physique
              </h1>
            </div>

            <div className="mt-3">
              <h1 className="text-sm font-regular">Indicateur de résultat</h1>
              <p className="text-sm font-light">
                {sous_activite?.indicateur_resultat}
              </p>

              <h1 className="text-sm font-regular mt-2">Unité</h1>
              <p className="text-sm font-light">
                {sous_activite?.indicateur_unite}
              </p>
            </div>

            <div className="my-3">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      Cible prévue
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="font-bold text-sm text-color-plt-1 text-center"
                    >
                      {sous_activite?.indicateur_cible}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="my-3">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      Cumul réel
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="font-bold text-sm text-color-plt-1 text-center"
                    >
                      {evolutions_last?.cumul_realisation || 0}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="pb-2">
              <table className="table">
                <thead className="bg-color-plt-1 text-light">
                  <tr>
                    <td className="font-bold text-sm text-center" colSpan={2}>
                      % de réalisation physique
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      colSpan={2}
                      className="font-bold text-sm text-color-plt-1 text-center"
                    >
                      <EvolutionTimeLine
                        value={
                          evolutions_last?.cumul_realisation
                            ? parseFloat(
                                (evolutions_last?.cumul_realisation /
                                  sous_activite?.indicateur_cible) *
                                  100
                              ).toFixed(0) 
                            : 0
                        }
                        width={`100%`}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex-grow xl:ml-2">
          <div className="bg-white rounded-lg p-2 mb-5">
            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Référence</span>
              <span className="font-light text-md">
                {sous_activite?.reference}
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Designation</span>
              <span className="font-light text-md">
                {sous_activite?.designation}
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Periode</span>
              <span className="font-light text-md capitalize">
                {obtenirPeriode(
                  sous_activite?.date_debut,
                  sous_activite?.date_fin
                )}
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Categorie de depense</span>
              <span className="font-light text-md">
                {
                  getElementData(
                    sous_activite?.categorie_depense,
                    categorieDepenses
                  )?.designation
                }
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Nature de l'activité</span>
              <span className="font-light text-md">
                {sous_activite?.nature}
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Composante</span>
              <span className="font-light text-md">
                {
                  getElementData(sous_activite?.composante, composantes_data)
                    ?.designation
                }
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Sous composante</span>
              <span className="font-light text-md">
                {
                  getElementData(
                    sous_activite?.sous_composante,
                    sousComposantes_data
                  )?.designation
                }
              </span>
            </div>

            <div className="flex flex-col border-b-[1px] pb-2 mt-3">
              <span className="font-medium text-md">Volet d'activité</span>
              <span className="font-light text-md">
                {
                  getElementData(
                    sous_activite?.volet_activite.voletsActivites_data
                  )?.designation
                }
              </span>
            </div>

            <div className="flex flex-col pb-2 mt-3">
              <span className="font-medium text-md">Activité</span>
              <span className="font-light text-md">
                {
                  getElementData(sous_activite?.activite, activites)
                    ?.designation
                }
              </span>
            </div>
          </div>

          <div className="bg-white p-2 rounded-md mb-4">
            <div className="flex items-center justify-end py-0">
              <span className="font-bold text-sm text-color-plt-1 w-full h-[1px] bg-slate-200"></span>

              {check_admin_composante(user, sous_activite?.composante) && (
                <LinkBtn
                  icon={<AiOutlinePlusCircle />}
                  className={"text-color-plt-1 text-[30px]"}
                  path={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${id_sous_activite}/evolution/nouvelle`}
                />
              )}
            </div>

            <div className="mt-3">
              {evolutions_all?.map((item, index) => {
                let bgTimeLine = "text-color-plt-2";

                if (item.pourcentage < 20) {
                  bgTimeLine = "text-color-plt-6";
                } else if (item.pourcentage < 70) {
                  bgTimeLine = "text-color-plt-4";
                } else {
                  bgTimeLine = "text-color-plt-2";
                }

                return (
                  <div
                    key={index}
                    className={`border-[1px] ${
                      index === 0 ? "mt-0" : "mt-6"
                    } mb-2 p-1 rounded relative`}
                  >
                    {check_admin_composante(user, sous_activite?.composante) &&
                      item.validated === null && (
                        <div className="flex justify-end absolute top-0 right-0">
                          <LinkBtn
                            icon={<AiFillEdit />}
                            className={"text-color-plt-1 text-lg"}
                            path={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${id_sous_activite}/evolution/${item.id}`}
                          />
                        </div>
                      )}

                    <div>
                      <span className={`font-medium text-sm`}>
                        Montant de la réalisation :{" "}
                      </span>
                      <span className={`text-gray-500 font-bold`}>
                        {FormatPrice(item.montant_realisation)} FCFA
                      </span>
                    </div>

                    <div>
                      <span className={`font-medium text-sm`}>
                        Cumul réel (en {sous_activite.indicateur_unite}) :{" "}
                      </span>
                      <span className={`text-gray-500 font-bold`}>
                        {item.cumul_realisation}
                      </span>
                    </div>

                    <div>
                      <span className={`font-medium text-sm`}>Status : </span>
                      <span className={`text-gray-500 font-bold`}>
                        {item.status}
                      </span>
                    </div>

                    {item?.fichier && (
                      <div>
                        <span className={`font-medium text-sm`}>
                          Fichier (justificatif) :{" "}
                        </span>
                        <span className={`text-gray-500 font-bold`}>
                          <Link to={item?.fichier} target="_blank">
                            {item?.fichier}
                          </Link>
                        </span>
                      </div>
                    )}

                    <div className="my-2">
                      <span className="font-medium text-sm">
                        Commentaire :{" "}
                      </span>
                      <div>
                        <p>{item.commentaire}</p>
                      </div>
                    </div>

                    {item.fichier && (
                      <div>
                        <span className="font-medium text-sm">
                          Fichier joint :{" "}
                        </span>
                        <div>
                          <a href={item.fichier} download>
                            {item.fichier}
                          </a>
                        </div>
                      </div>
                    )}

                    {check_admin(user) && item.validated === null && (
                      <div className="flex justify-between mt-3">
                        <button
                          onClick={() => hundleValidation(item.id, false)}
                          className="w-[49%] h-10 text-color-plt-6 border-color-plt-6 rounded border-[1px] flex justify-center items-center"
                        >
                          <AiOutlineClose />
                          <span className="ml-2 text-md">Désapprouver</span>
                        </button>

                        <button
                          onClick={() => hundleValidation(item.id, true)}
                          className="w-[49%] h-10 text-color-plt-1 border-color-plt-1 rounded border-[1px] flex justify-center items-center"
                        >
                          <AiOutlineCheck />
                          <span className="ml-2 text-md">Approuver</span>
                        </button>
                      </div>
                    )}

                    {item.validated !== null && (
                      <div className="flex justify-between mt-3">
                        {item.validated ? (
                          <div className="w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]">
                            <AiOutlineCheck />
                            <span className="text-sm ml-2">Approuvé</span>
                          </div>
                        ) : (
                          <div className="w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]">
                            <AiOutlineClose />
                            <span className="text-sm ml-2">Désapprouvé</span>
                          </div>
                        )}
                      </div>
                    )}

                    {user.profile !== "client-admin" &&
                      item.validated === null && (
                        <div className="w-full border-[1px] h-10 rounded flex items-center justify-center text-[gray]">
                          <AiOutlineCheck />
                          <span className="text-sm ml-2">
                            En attente de validation
                          </span>
                        </div>
                      )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </MainContent>
  );
};

export default SousActiviteDetails;
