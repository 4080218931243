import React from 'react'
import { EvolutionTimeLine, LinkBtn, MainContent } from '../../../components'
import { Link, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { FormatPrice, check_admin, getElementData } from '../../../constances';

const PtbaView = () => {

    const {id_unite_gestion} = useParams();
    const {ptba, unites_gestions, user} = usePlateformContext();

    let ptba_data = ptba.filter(item => item.unite_gestion.toString() === id_unite_gestion) 
    let unite_gestion = getElementData(id_unite_gestion, unites_gestions)

    let total_ptba_all = 0

    console.log(ptba)

  return (
    <MainContent title={`Programme de Travail et Budget Annuel (PTBA)`}>
        {/* Entete */}
        <div className='flex items-center mt-5 justify-between px-2 md:px-1 py-4'>

            <div className='xl:max-w-[70%] max-w-[50%]'>
                <h1 className='font-bold text-lg'>PTBA</h1>
                <p className='text-md font-light'>Liste des Programmes de Travail et Budget Annuel pour le projet</p>
            </div>

            <div className='flex items-center'>
                {check_admin(user) && <LinkBtn
                    description={"Nouveau PTBA"}
                    icon={<AiOutlinePlusCircle/>}
                    path={`/unites-gestions/${id_unite_gestion}/ptba/nouvelle`}
                    className={'bg-color-plt-1 text-light md:w-auto w-[100%] mb-2 md:mb-0'}
                />}
            </div>
        </div> 

        {/* Tableau des composantes sous-composantes et volets d'activites */}
        <div className='container px-2 p-4'>
            {/* Composantes */}
            <div className='table-container mb-10'>
                <table className='table'>
                    <thead className='bg-dark text-light'>
                        <th>N°</th>
                        <th>Projet</th>
                        <th>Periodes</th>
                        <th>Total en FCFA</th>
                        <th>Total en {unite_gestion?.devise}</th>
                        <th>Activités</th>
                        {/* <th>Evolution %</th> */}
                        <th>Description</th>
                    </thead>
                    <tbody>
                        {ptba_data.map((item, index) => {

                            total_ptba_all = total_ptba_all + item.total_ptba

                            return(
                                <tr>
                                    <td className='w-[50px]'>{index + 1}</td>
                                    <td className='whitespace-normal'>{item.projet}</td>
                                    <td>{item.annee}</td>
                                    <td>{FormatPrice(item.total_ptba)}</td>
                                    <td>{FormatPrice(parseFloat(item.total_ptba / parseInt(unite_gestion?.valeur_devise)).toFixed(0))}</td>
                                    <td className='text-center'>{item.activite_total}</td>
                                    {/* <td>
                                        <EvolutionTimeLine value={parseFloat(item.evolution).toFixed(0)}/>
                                    </td> */}
                                    <td className='whitespace-normal'>{item.description}</td>
                                    <td>
                                        <span className='flex'>
                                            <Link 
                                                to={`/unites-gestions/${id_unite_gestion}/ptba/${item.id}/details`}
                                            >Voir les activités</Link>
                                        </span>
                                    </td>
                                    {check_admin(user) && <td className='w-[90px]'>
                                        <span className='flex'>
                                            <LinkBtn
                                                description={"Modifier"}
                                                icon={<AiOutlineEdit/>}
                                                className={"h-5"}
                                                path={`/unites-gestions/${id_unite_gestion}/ptba/${item.id}`}
                                            />
                                        </span>
                                    </td>}
                                </tr>
                            )
                        })}


                        {ptba_data.length > 0 && <tr className='font-medium' style={{backgroundColor: "#C5E0B3"}}>
                                <td colSpan={3}>Total</td>
                                <td>{FormatPrice(total_ptba_all)}</td>
                                <td>{FormatPrice(parseFloat(total_ptba_all / unite_gestion?.valeur_devise).toFixed(0))}</td>
                                <td colSpan={5}></td>
                            </tr>}

                        {!ptba_data.length > 0 && <tr>
                                <td colSpan={7} className='h-20 text-center'>Aucun PTBA</td>
                            </tr>}
                    </tbody>
                </table>
            </div>
        </div>
    </MainContent>
  )
}

export default PtbaView