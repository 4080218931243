import React, { Fragment, useEffect, useState } from 'react'
import { EvolutionTimeLine, LinkBtn, MainContent, Select } from '../../../components'
import { Link, useParams } from 'react-router-dom';
import { usePlateformContext } from '../../../contexts';
import { AiFillPrinter, AiOutlineEdit, AiOutlinePlusCircle } from 'react-icons/ai';
import { FormatPrice, IMAGES, check_admin, check_admin_composante, getElementData, obtenirPeriode } from '../../../constances';

const PtbaDetailsView = () => {

    const {id_unite_gestion, id_ptba} = useParams();
    const {ptba, unites_gestions, sousComposantes, voletsActivites, composantes, activites, sousActivites, user} = usePlateformContext();

    let ptba_selected = getElementData(id_ptba, ptba)
    let ptba_data = ptba.filter(item => item.unite_gestion.toString() === id_unite_gestion) 


    // Filtrer par composantes
    let model_data = {
        composante: "",
    };

    const [credentials, setCredentials] = useState(model_data);

    const handleChange = (e) => {
        setCredentials({...credentials, [e.target.name] : e.target.value})
    }

    const [optionsComposantes, setOptionsComposantes] = useState([]);


    // Filtrer datas

    let composantes_data = composantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let sousComposantes_data = sousComposantes.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let voletsActivites_data = voletsActivites.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let activites_data = activites.filter(item => item.unitegestion.toString() === id_unite_gestion)
    let sous_activites_data = sousActivites.filter(item => item.unite_gestion.toString() === id_unite_gestion)
    let unite_gestion = getElementData(id_unite_gestion, unites_gestions)

    let activites_exists = false;

    let valeur_devise = unite_gestion?.valeur_devise ? parseInt(unite_gestion?.valeur_devise) : 1

    let total_ptba = 0;

    activites_data.map(item => {
        total_ptba = total_ptba + (item.montant);
    })

    // Filter data
    let composantes_sous_activite = composantes_data.filter(item => {
        if (credentials.composante) {
            return item.id.toString() === credentials.composante
        } else {
            return true
        }
    })

    useEffect(() => {
        let liste_composantes = [
            {value: "", label: "Toutes les composantes"}
        ];

        composantes_data.map(item => {
            return liste_composantes.push({value : item.id, label: `${item.reference}`})
        })

        setOptionsComposantes(liste_composantes);
    }, [composantes])
    

  return (
    <MainContent title={`Retour`}>
        {/* Entete */}
        {/* <div className='flex items-center mt-5 justify-end md:flex-row flex-col px-2 md:px-1 py-4'>
            <LinkBtn
                description={"Imprimer"}
                icon={<AiFillPrinter/>}
                className={'bg-color-plt-6 text-light w-[100%] md:w-auto mb-2 md:mb-0'}
            />
        </div> */}

        <div className='flex items-center justify-between xl:flex-row flex-col my-5 px-2 w-full'>
            <div className='xl:mb-0 mb-3'>
                <h1 className='font-bold mb-2'>Résumé des activités du PTBA {ptba_selected?.annee}</h1>
                <p className='text-sm'>{ptba_selected?.description}</p>
            </div>

            <div className='xl:w-auto w-full'>
                <Select
                    label={"Filtrer par composante"}
                    options={optionsComposantes}
                    className={"xl:w-[300px] w-full"}
                    name={"composante"}
                    value={credentials.composante}
                    onChange={handleChange}
                />
            </div>
        </div>

        {/* <div>
             <span key={index_composante}>{composante.designation}</span>
            })}
        </div> */}

        <div className='table-container mb-10'>
            <table className='table'>
            {composantes_sous_activite.map((composante, index_composante) => {

                // Verifier si le composant a bien les sous activites
                let verification_sous_activite_composante = ptba_selected?.sous_activites?.filter(item => item.composante === composante.id)?.length > 0
                let somme_sous_activite = 0;

                return (
                    <Fragment key={index_composante}>
                        <thead className='bg-dark text-light mt-10'>
                            <tr>
                                <th colSpan={check_admin(user) ? 6 : 5}>{composante.reference}: {composante.designation}</th>
                            </tr>
 
                            {verification_sous_activite_composante && <tr className='text-black' style={{backgroundColor: "#C5E0B3"}}>
                                <th>N°</th>
                                <th>Activité</th>
                                <th>Montant ({"FCFA"})</th>
                                <th>Montant ({unite_gestion?.devise})</th>
                                <th>Periode d'execution</th>
                                {check_admin(user) &&<th></th>}
                            </tr>}
                        </thead>
                        <tbody>
                            {verification_sous_activite_composante && sousComposantes_data.filter(item => item.composante === composante.id).map((sous_composante, index_sous_composante) => {
                                
                                let verification_sous_activite_sous_composante = ptba_selected?.sous_activites?.filter(item => item.sous_composante === sous_composante.id)?.length > 0

                                
                                return verification_sous_activite_sous_composante && (
                                    <Fragment key={index_sous_composante}>
                                        <tr className='font-medium' style={{backgroundColor: "#C5E0B3"}}>
                                            <td colSpan={check_admin(user) ? 6 : 5}>{sous_composante.reference}: {sous_composante.designation}</td>
                                        </tr>

                                        {voletsActivites_data.filter(item => item.sous_composante === sous_composante.id).map((volet_activite, index_volet_activite) => {
                                            
                                            let verification_sous_activite_volet_activite = ptba_selected?.sous_activites?.filter(item => item.volet_activite === volet_activite.id)?.length > 0
                                                   
                                            return verification_sous_activite_volet_activite && (
                                                <Fragment key={index_volet_activite}>
                                                    <tr className='font-medium' style={{backgroundColor: "#C5E0B3"}}>
                                                        <td colSpan={check_admin(user) ? 6 : 5}>{volet_activite.reference}. {volet_activite.designation}</td>
                                                    </tr>

                                                    {activites_data.filter(item => item.volet_activite === volet_activite.id).map((activite, index_activite) => {
                                                        
                                                        let verification_sous_activite_activite = ptba_selected?.sous_activites?.filter(item => item.activite === activite.id)?.length > 0

                                                        return verification_sous_activite_activite && (
                                                            <Fragment>
                                                                <tr className='font-medium' style={{backgroundColor: "#C5E0B3"}}>
                                                                    <td colSpan={check_admin(user) ? 6 : 5}>{activite.designation}</td>
                                                                </tr>

                                                                {ptba_selected.sous_activites && ptba_selected.sous_activites.filter(item => item.activite === activite.id ).map((sous_activite, index_sous_activite) => {
                                                                    
                                                                    somme_sous_activite = somme_sous_activite + sous_activite.montant

                                                                    return(
                                                                        <Fragment key={index_sous_activite}>
                                                                            <tr>
                                                                                <td>{sous_activite.reference}</td>
                                                                                <td>{sous_activite.designation}</td>
                                                                                <td>{sous_activite.montant}</td>
                                                                                <td>{FormatPrice((sous_activite.montant / valeur_devise).toFixed(0))}</td>
                                                                                <td>{obtenirPeriode(sous_activite.date_debut, sous_activite.date_fin)}</td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    )
                                                                })}

                                                                
                                                            </Fragment>
                                                        )
                                                    })}
                                                </Fragment>
                                            )
                                        })}
                                        
                                    </Fragment>
                                )
                            })}
                            

                            {/* Calcule du total de la composante */}
                            {verification_sous_activite_composante &&<tr className='bg-dark text-light mt-10' style={{backgroundColor: "#4B5563"}}>
                                <td colSpan={2}>Total composante</td>
                                <td>{FormatPrice(somme_sous_activite)}</td>
                                <td>{FormatPrice((somme_sous_activite / valeur_devise).toFixed(0))}</td>
                                <td colSpan={2}></td>
                            </tr>}

                            {!verification_sous_activite_composante && <tr className=''>
                                <td colSpan={check_admin(user) ? 6 : 5} className='border-1 h-10'>Aucune sous activité</td>
                            </tr>}

                            <tr className='bg-[transparent]' style={{backgroundColor: 'transparent'}}>
                                <td colSpan={check_admin(user) ? 6 : 5} className='border-0 h-10'></td>
                            </tr>
                        </tbody>
                    </Fragment>
                )
                
                })}
            </table>
        </div>

        

        
    </MainContent>
  )
}

export default PtbaDetailsView