import { useState, useRef, useEffect } from "react";

export default function SearchableSelect({
  options,
  value,
  onChange,
  placeholder,
  className = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const selectRef = useRef(null);

  // Fermer si clic en dehors
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Filtrer les options selon la recherche
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase())
  );

  // Sélectionner une option
  const handleSelect = (option) => {
    onChange(option); // Met à jour la valeur dans le parent
    setIsOpen(false);
    setSearch(""); // Réinitialiser la recherche
  };

  // Gestion du clavier (flèches + Entrée)
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setHighlightedIndex((prev) => (prev + 1) % filteredOptions.length);
    } else if (e.key === "ArrowUp") {
      setHighlightedIndex(
        (prev) => (prev - 1 + filteredOptions.length) % filteredOptions.length
      );
    } else if (e.key === "Enter") {
      handleSelect(filteredOptions[highlightedIndex]);
    }
  };

  return (
    <div ref={selectRef} className={`relative w-full ${className}`}>
      {/* Sélecteur principal */}
      <div
        className="flex items-center justify-between border border-gray-300 px-2 h-[41px] cursor-pointer bg-white"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="truncate w-full">
          {value ? options?.find((e) => e.value === value)?.label : placeholder}
        </span>
        <span className="ml-2">▼</span>
      </div>

      {/* Liste déroulante avec recherche */}
      {isOpen && (
        <div className="absolute left-0 w-full bg-white border border-gray-300 rounded-lg mt-1 shadow-md z-10">
          {/* Champ de recherche */}
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={handleKeyDown}
            className="w-full p-2 border-b border-gray-300 outline-none"
            placeholder="Rechercher..."
            autoFocus
          />

          {/* Liste filtrée des options */}
          <ul className="max-h-40 overflow-auto">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, index) => (
                <li
                  key={option.value}
                  className={`px-2 py-3 cursor-pointer whitespace-normal border-b ${
                    index === highlightedIndex
                      ? "bg-gray-200"
                      : "hover:bg-gray-100"
                  }`}
                  title={option.label}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500 text-center">
                Aucune option trouvée
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}
