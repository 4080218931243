// import jwtDecode from "jwt-decode"
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export const tokenIsValid = (token) => {
  // const { exp } = jwtDecode(token);

  // if(exp * 1000 > new Date().getTime()){
  //     return true;
  // }

  return !!token;
};

export function filtrerData(id, list_element = []) {
  const response = list_element.find(
    (item) => item.id.toString() === id.toString()
  );
  return response || null;
}

export const formaterDate = (date) => {
  let now = new Date(date);
  // return format(now, 'EEE dd MMM yyyy', { locale: fr });
  return format(now, "dd/MM/yyyy", { locale: fr });
};

export const FormatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { day: "2-digit", month: "short", year: "numeric" };
  const formattedDate = date
    .toLocaleDateString("fr-FR", options)
    .replace(/ /g, " ");

  return formattedDate; // Exemple de résultat : 20-Apr-2023
};

export const FormatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  const formattedDate = date
    .toLocaleDateString("fr-FR", options)
    .replace(/ /g, " ");

  return formattedDate; // Exemple de résultat : 20-Apr-2023
};

export const FormatPrice = (arg) => {
  //const value = new Intl.NumberFormat('fr-FR').format(arg);
  return String(arg).replace(/(.)(?=(\d{3})+$)/g, "$1 ");
};

export function getShortenedName(imagePath, maxLength) {
  const maxChars = maxLength || 30; // Nombre maximal de caractères avant la troncature (20 par défaut)
  const ellipsis = "...";

  if (imagePath.length <= maxChars) {
    return imagePath;
  } else {
    const truncatedName =
      imagePath.substring(0, maxChars - ellipsis.length) + ellipsis;
    return truncatedName;
  }
}

export const getElementData = (id, element) => {
  let response = {};

  try {
    response = element.find((item) => item.id.toString() === id.toString());

    return response;
  } catch (error) {
    return response;
  }
};

// Fonction pour calculer la différence en jours entre deux dates
export function calculerDifferenceEnJours(date1, date2) {
  // Convertir les dates en objets de type Date
  var date1Obj = new Date(date1);
  var date2Obj = new Date(date2);

  // Calculer la différence en millisecondes
  var differenceMs = Math.abs(date2Obj - date1Obj);

  // Convertir la différence en jours
  var differenceJours = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

  // Retourner le résultat
  return differenceJours;
}

export const ValidePhone = (inputPhone) => {
  // let phoneRegex = new RegExp('^[0]{1}[6,4,5]{1}[0-9]{7}$', 'g');

  // seulement les numero mtn
  let phoneRegex = new RegExp("^[0]{1}[6,5,4]{1}[0-9]{7}$", "g");

  let testPhone = phoneRegex.test(inputPhone);

  if (testPhone) {
    return true;
  } else {
    return false;
  }
};

export const FormatageNumero = (arg) => {
  var value = arg;
  //const value = new Intl.NumberFormat('fr-FR').format(arg);
  return String(value).replace(/(.{2})(.{3})(.{2})(.{2})/g, "$1 $2 $3 $4");
};

export const supprimerUnderscores = (texte) => {
  try {
    return texte.replace(/_/g, " ");
  } catch (error) {
    return texte;
  }
};

export function checkPermission(permission, list_permission = []) {
  const response = list_permission.find(
    (item) => item.toLowerCase() === permission.toLowerCase()
  );
  return response !== undefined && response.length > 0;
}

export function checkIfAnyElementInListExists(listToCheck, targetList) {
  return listToCheck.some((item) => targetList.includes(item));
}

export const check_admin = (user) => {
  return user?.profile === "client-admin";
};

export const check_admin_composante = (user, composantes) => {
  const composantesArray = Array.isArray(composantes) ? composantes : [composantes];

  return (
    user?.profile === "client-admin" || 
    user?.composantes?.some((c) => composantesArray.includes(c))
  );
};

export function obtenirPeriodeComplet(dateDebut, dateFin) {
  if (!dateDebut && !dateFin) {
    return "Aucune";
  }

  const options = { month: "long", year: "numeric" };

  if (dateDebut && dateFin) {
    const debut = new Date(dateDebut);
    const fin = new Date(dateFin);

    if (isNaN(debut.getTime()) || isNaN(fin.getTime())) {
      return "Dates non valides";
    }

    const debutFormatte = debut.toLocaleDateString("fr-FR", options);
    const finFormattee = fin.toLocaleDateString("fr-FR", options);

    if (
      debut.getFullYear() === fin.getFullYear() &&
      debut.getMonth() === fin.getMonth()
    ) {
      return debutFormatte;
    } else {
      return `${debutFormatte} - ${finFormattee}`;
    }
  } else if (dateDebut) {
    const debut = new Date(dateDebut);

    if (isNaN(debut.getTime())) {
      return "Date non valide";
    }

    return debut.toLocaleDateString("fr-FR", options);
  } else if (dateFin) {
    const fin = new Date(dateFin);

    if (isNaN(fin.getTime())) {
      return "Date non valide";
    }

    return fin.toLocaleDateString("fr-FR", options);
  } else {
    return "Aucune";
  }
}

export function obtenirPeriode(dateDebut, dateFin) {
  if (!dateDebut && !dateFin) {
    return "Aucune";
  }

  const options = { month: "short", year: "numeric" };

  if (dateDebut && dateFin) {
    const debut = new Date(dateDebut);
    const fin = new Date(dateFin);

    if (isNaN(debut.getTime()) || isNaN(fin.getTime())) {
      return "Dates non valides";
    }

    const debutFormatte = debut.toLocaleDateString("fr-FR", options);
    const finFormattee = fin.toLocaleDateString("fr-FR", options);

    if (
      debut.getFullYear() === fin.getFullYear() &&
      debut.getMonth() === fin.getMonth()
    ) {
      return debutFormatte;
    } else {
      return `${debutFormatte} - ${finFormattee}`;
    }
  } else if (dateDebut) {
    const debut = new Date(dateDebut);

    if (isNaN(debut.getTime())) {
      return "Date non valide";
    }

    return debut.toLocaleDateString("fr-FR", options);
  } else if (dateFin) {
    const fin = new Date(dateFin);

    if (isNaN(fin.getTime())) {
      return "Date non valide";
    }

    return fin.toLocaleDateString("fr-FR", options);
  } else {
    return "Aucune";
  }
}

export const normalizeString = (str) => {
  return str
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();
};

export const exportToExcel = (fileName) => {
  const table = document.querySelector(".table-export-excel");
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.table_to_sheet(table);
  XLSX.utils.book_append_sheet(wb, ws, "Tableau");
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(data, `${fileName}.xlsx`);
};

export const exportToPDF = (fileName) => {
  const input = document.querySelector(".table-export-pdf");
  html2canvas(input, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("l", "mm", "a4");

    // Ajustez les marges ici (par exemple, marge de 20mm pour la gauche et la partie supérieure)
    const marginLeft = 3.5;
    const marginTop = 3;

    const imgWidth = 290;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", marginLeft, marginTop, imgWidth, imgHeight);
    pdf.save(`${fileName}.pdf`);
  });
};

export function getObservation(startDate, endDate) {
  const now = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  if (now < start) {
      return { value: "upcoming", label: "À venir" };
  } else if (now >= start && now <= end) {
      return { value: "on_time", label: "Dans les délais" };
  } else {
      return { value: "late", label: "En retard" };
  }
}


export function toggleKey(obj, key) {
  const newObj = { ...obj };
  if (key in newObj && typeof(newObj[key]) === "string") {
    delete newObj[key];
  } else {
    newObj[key] = "";
  }
  return newObj;
}
