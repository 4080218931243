import React from "react";
import { Button, Input, MainContent, Select } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import { getElementData } from "../../../constances";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import SearchableSelect from "../../../components/ui/SearchableSelect";
import { toggleKey } from "../../../constances/functions";
import { MdAdd } from "react-icons/md";
import { FiTrash } from "react-icons/fi";

const DemandeANO = () => {
  const navigate = useNavigate();
  const { id_demande_ano, id_unite_gestion } = useParams();
  const {
    update_demande_ano,
    create_demande_ano,
    delete_demande_ano,
    activites,
    user,
    demandesANO,
  } = usePlateformContext();

  let check_activite = id_demande_ano === "nouvelle";

  let demande_ano = getElementData(id_demande_ano, demandesANO);

  let activites_data = activites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  let model_data = {
    utilisateur: user.id || "",
    activite: "",
    reference: "",
    designation: "",

    date_transmission: "",

    // date_reel: "",
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleChangeError = (name, value) => {
    setCredentialsError({ ...credentialsError, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation du formulaire

    const errors = {}; // Objet pour stocker les erreurs

    if (!credentials?.reference) {
      errors.reference = "Entrez la référence";
    }
    if (!credentials?.designation) {
      errors.designation = "La designation est requise !";
    }
    if (!credentials?.utilisateur) {
      errors.utilisateur = "L'utilisateur est requis !";
    }
    // if (!credentials?.nb_jour_reel || isNaN(credentials?.nb_jour_reel)) {
    //   errors.nb_jour_reel = "Le nombre de jours réels doit être un nombre !";
    // }
    // if (!credentials?.date_probable) {
    //   errors.date_probable = "Date probable invalide !";
    // }

    if ("date_reel" in { ...credentials } && !credentials?.date_reel && typeof(credentials?.date_reel) === "string") {
      errors.date_reel = "Date réelle invalide !";
    }

    if (!credentials?.date_transmission) {
      errors.date_transmission = "Date de transmission !";
    }
    // if (!credentials?.statut) {
    //   errors.statut = "Selectionner le statut";
    // }
    if (!credentials?.activite) {
      errors.activite = "Selectionner l'activité";
    }

    if (Object.keys(errors).length > 0) {
      setCredentialsError(errors);
    } else {
      let response = !check_activite
        ? await update_demande_ano(credentials)
        : await create_demande_ano(credentials);

      if (response) {
        navigate(-1);
      }
    }
  };

  const handleAddDateReel = () => {
    const data = toggleKey(credentials, "date_reel");
    setCredentials(data);
  };

  useEffect(() => {
    setCredentials(check_activite ? model_data : demande_ano);
  }, [demandesANO]);

  // Options composantes
  const [optionsActivites, setOptionsActivites] = useState([]);

  const optionsStatut = [
    { value: "en_attente", label: `En attente` },
    { value: "accorde", label: `Accordé` },
  ];

  useEffect(() => {
    let liste_composantes = [];

    activites_data.map((item) => {
      return liste_composantes.push({
        value: item.id,
        label: `${item.reference}: ${item.designation}`,
      });
    });

    setOptionsActivites(liste_composantes);
  }, [activites]);


  return (
    <MainContent
      title={`${
        check_activite ? "Enregistrer une demande" : demande_ano?.reference
      }`}
    >
      <div className="flex items-center mt-5 justify-between px-2 md:px-1 py-4">
        <div className="xl:max-w-[70%] max-w-[50%]">
          <h1 className="font-bold text-lg">
            {check_activite ? "Nouvelle activité" : "Modifier l'activité"}
          </h1>
          <p className="text-md font-light">
            {check_activite
              ? "Enregistrer une nouvelle activité pour le projet"
              : `Modifier l'activité "${demande_ano?.designation}"`}
          </p>
        </div>

        <div className="flex items-center"></div>
      </div>

      <div className="flex flex-col justify-start items-center py-3">
        <form
          onSubmit={handleSubmit}
          className="w-[700px] max-w-[95%] bg-white p-3 rounded-md"
        >
          <div>
            <SearchableSelect
              options={optionsActivites}
              value={credentials?.activite}
              placeholder="Sélectionnez une activité"
              onChange={(e) =>
                handleChange({ target: { name: "activite", value: e.value } })
              }
            />

            {/* <Select
              label={"Activité"}
              required={true}
              placeholder={"Selectionner l'activité"}
              autoComplete="off"
              value={credentials?.activite}
              error={credentialsError.activite}
              onChange={handleChange}
              onFocus={() => handleChangeError("activite", null)}
              name={"activite"}
              options={optionsActivites}
            /> */}

            <Input
              type={"text"}
              label={"Référence"}
              required={true}
              placeholder={"Ex: A.1.1"}
              autoComplete="off"
              value={credentials?.reference}
              error={credentialsError.reference}
              onChange={handleChange}
              onFocus={() => handleChangeError("reference", null)}
              name={"reference"}
            />

            <Input
              type={"text"}
              label={"Designation"}
              required={true}
              placeholder={"Designation"}
              autoComplete="off"
              value={credentials?.designation}
              error={credentialsError.designation}
              onChange={handleChange}
              onFocus={() => handleChangeError("designation", null)}
              name={"designation"}
            />

            <Input
              type={"date"}
              label={"Date de transmission"}
              required={true}
              placeholder={"Date de transmission"}
              autoComplete="off"
              value={credentials?.date_transmission}
              error={credentialsError.date_transmission}
              onChange={handleChange}
              onFocus={() => handleChangeError("date_transmission", null)}
              name={"date_transmission"}
              className={"w-full xl:mr-1"}
            />

            {!check_activite && (
              <div className="my-5">
                {(!("date_reel" in { ...credentials }) || typeof(credentials?.date_reel) !== "string") && (
                  <div className="flex justify-between items-center">
                    <h4>Enregistrer la date à laquelle la demande a été accordée</h4>
                    <div
                      onClick={handleAddDateReel}
                      className="w-6 h-6 cursor-pointer text-white bg-secondary rounded-full flex justify-center items-center"
                    >
                      <MdAdd />
                    </div>
                  </div>
                )}

                {("date_reel" in { ...credentials } && typeof(credentials?.date_reel) === "string") && (
                  <div className="flex justify-between items-center gap-4 min-h-[40px]">
                    <Input
                      type={"date"}
                      label={"Date reel"}
                      required={true}
                      placeholder={"Date reel"}
                      autoComplete="off"
                      value={credentials?.date_reel}
                      error={credentialsError.date_reel}
                      onChange={handleChange}
                      onFocus={() => handleChangeError("date_reel", null)}
                      name={"date_reel"}
                      className={"w-full xl:mr-1"}
                    />

                    <div
                      onClick={handleAddDateReel}
                      className="w-6 h-6 cursor-pointer mt-7 text-sm text-white bg-red-600 rounded-full flex justify-center items-center"
                    >
                      <FiTrash />
                    </div>
                  </div>
                )}
              </div>
            )}

            {/* <Input
              type={"number"}
              label={"Nombre de jours réels"}
              required={true}
              placeholder={"Nombre de jours réels"}
              autoComplete="off"
              value={credentials?.nb_jour_reel}
              error={credentialsError.nb_jour_reel}
              onChange={handleChange}
              onFocus={() => handleChangeError("nb_jour_reel", null)}
              name={"nb_jour_reel"}
            /> */}

            {/* <Input
              type={"date"}
              label={"Date reel"}
              required={true}
              placeholder={"Date reel"}
              autoComplete="off"
              value={credentials?.date_reel}
              error={credentialsError.date_reel}
              onChange={handleChange}
              onFocus={() => handleChangeError("date_reel", null)}
              name={"date_reel"}
              className={"w-full xl:mr-1"}
            /> */}

            {/* <Input
              type={"date"}
              label={"Date probable"}
              required={true}
              placeholder={"Date probable"}
              autoComplete="off"
              value={credentials?.date_probable}
              error={credentialsError.date_probable}
              onChange={handleChange}
              onFocus={() => handleChangeError("date_probable", null)}
              name={"date_probable"}
              className={"w-full xl:mr-1"}
            /> */}

            {/* <Select
              label={"Statut"}
              required={true}
              placeholder={"Selectionner le statut"}
              autoComplete="off"
              value={credentials?.statut}
              error={credentialsError.statut}
              onChange={handleChange}
              onFocus={() => handleChangeError("statut", null)}
              name={"statut"}
              options={optionsStatut}
            /> */}

            <Button
              type="submit"
              text={"Enregistrer"}
              bgColor={"bg-color-plt-1"}
            />
          </div>
        </form>

        {!check_activite && (
          <div className="w-[700px] max-w-[95%] bg-white p-3 rounded-md">
            <button
              onClick={() => {
                delete_demande_ano(demande_ano);
                navigate(-1);
              }}
              className="text-color-plt-6 flex items-center text-[12px]"
            >
              <AiOutlineDelete />
              <span className="ml-1">Supprimer</span>
              <span className="ml-1 text-dark">(non recommandé)</span>
            </button>
          </div>
        )}
      </div>
    </MainContent>
  );
};

export default DemandeANO;
