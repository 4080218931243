import React from "react";
import {
  Button,
  Input,
  MainContent,
  Select,
  Textarea,
} from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import { getElementData } from "../../../constances";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

const EvolutionSousActiviteForm = () => {
  const navigate = useNavigate();
  const { id_sous_activite, id_evolution } = useParams();
  const {
    taches,
    sousActivites,
    user,
    update_evolution_tache,
    create_evolution_tache,
    delete_evolution_tache,
  } = usePlateformContext();

  let check_tache = id_evolution === "nouvelle";
  let tache = getElementData(id_sous_activite, taches);
  let sous_activite = getElementData(id_sous_activite, sousActivites);

  let evolutions_all = sous_activite?.evolutions_sous_activite?.sort(
    (a, b) => new Date(b.created) - new Date(a.created)
  );
  let evolutions_validated = evolutions_all?.filter((e) => e.validated);
  let evolutions_last = evolutions_validated ? evolutions_validated[0] : null;

  let evolutions_selected = getElementData(
    id_evolution,
    sous_activite?.evolutions_sous_activite
  );

  let model_data = {
    sous_activite: id_sous_activite,
    user: user?.id,
    pourcentage: "10",
    status: "",
    commentaire: "",
    fichier: null,
    montant_realisation: 0,
    cumul_realisation: 0,
  };

  const [credentials, setCredentials] = useState(model_data);
  const [credentialsError, setCredentialsError] = useState({});

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleChangeError = (name, value) => {
    setCredentialsError({ ...credentialsError, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation du formulaire

    const errors = {}; // Objet pour stocker les erreurs

    if (!credentials.status) {
      errors.status = "Selectionner le statut à ce jour";
    }

    if (!credentials.montant_realisation) {
      errors.montant_realisation =
        "Entrez le montant de la réalisation à ce jour.";
    } 
    // else if (credentials.montant_realisation > sous_activite.montant) {
    //   errors.montant_realisation =
    //     "Le montant de la réalisation ne peut pas être supérieur au montant prévu.";
    // } 
    else if (
      evolutions_last &&
      evolutions_last?.montant_realisation > credentials.montant_realisation
    ) {
      errors.montant_realisation =
        "Incohérence sur le montant de la réalisation : il ne peut pas être inférieur à celui des précédentes évaluations.";
    }

    if (!credentials.cumul_realisation) {
      errors.cumul_realisation = "Entrez le cumul réel à ce jour.";
    } 
    // else if (credentials.cumul_realisation > sous_activite.indicateur_cible) {
    //   errors.cumul_realisation =
    //     "Le cumul réel ne peut pas être supérieur à la cible prévue.";
    // } 
    else if (
      evolutions_last &&
      evolutions_last?.cumul_realisation > credentials.cumul_realisation
    ) {
      errors.cumul_realisation =
        "Incohérence sur le cumul réel : il ne peut pas être inférieur à celui des précédentes évaluations.";
    }

    if (!credentials.commentaire) {
      errors.commentaire =
        "Entrez un commentaire pour une bonne compréhension.";
    }

    if (Object.keys(errors).length > 0) {
      setCredentialsError(errors);
    } else {
      let response = !check_tache
        ? await update_evolution_tache(credentials)
        : await create_evolution_tache(credentials);

      if (response) {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    setCredentials(check_tache ? model_data : evolutions_selected);
  }, [taches]);

  let optionsStatuts = [
    // { value: "en attente", label: "En attente" },
    { value: "en cours", label: "En cours" },
    { value: "termine", label: "Terminé" },
  ];

  return (
    <MainContent title={`Evolution de l'activité`}>
      <div className="flex flex-col justify-start items-center py-3">
        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          className="w-[700px] max-w-[95%] bg-white p-3 rounded-md"
        >
          <div>
            <Input
              type={"number"}
              label={"Entrez le montant de la réalisation à ce jour."}
              required={true}
              placeholder={"Entrez le montant (en devise locale FCFA)."}
              autoComplete="off"
              value={credentials?.montant_realisation}
              error={credentialsError.montant_realisation}
              onChange={handleChange}
              onFocus={() => handleChangeError("montant_realisation", null)}
              name={"montant_realisation"}
            />

            <Input
              type={"number"}
              label={`Entrez le cumul réel de la cible à ce jour (en ${sous_activite.indicateur_unite})`}
              required={true}
              placeholder={"Entrez le cumul réel."}
              autoComplete="off"
              value={credentials?.cumul_realisation}
              error={credentialsError.cumul_realisation}
              onChange={handleChange}
              onFocus={() => handleChangeError("cumul_realisation", null)}
              name={"cumul_realisation"}
            />

            <Select
              type={"text"}
              label={"Sélectionnez le statut de l'activité."}
              required={true}
              placeholder={"Sélectionnez le statut de la tâche."}
              autoComplete="off"
              value={credentials?.status}
              error={credentialsError.status}
              onChange={handleChange}
              onFocus={() => handleChangeError("status", null)}
              name={"status"}
              options={optionsStatuts}
            />

            {/* <Input
              type={"file"}
              label={"Fichier (justificatif)."}
              placeholder={"Fichier (justificatif)."}
              autoComplete="off"
              value={credentials?.fichier}
              error={credentialsError.fichier}
              onChange={handleChange}
              onFocus={() => handleChangeError("fichier", null)}
              name={"fichier"}
              className="mt-2"
            /> */}

            <Textarea
              label={"Commentaire."}
              required={true}
              placeholder={"Commentaire."}
              autoComplete="off"
              value={credentials?.commentaire}
              error={credentialsError.commentaire}
              onChange={handleChange}
              onFocus={() => handleChangeError("commentaire", null)}
              name={"commentaire"}
            />

            <Button
              type="submit"
              text={"Enregistrer"}
              bgColor={"bg-color-plt-1"}
            />
          </div>
        </form>

        {!check_tache && (
          <div className="w-[700px] max-w-[95%] bg-white p-3 rounded-md">
            <button
              onClick={() => {
                delete_evolution_tache(evolutions_selected);
                navigate(-1);
              }}
              className="text-color-plt-6 flex items-center text-[12px]"
            >
              <AiOutlineDelete />
              <span className="ml-1">Supprimer</span>
              <span className="ml-1 text-dark">(non recommandé)</span>
            </button>
          </div>
        )}
      </div>
    </MainContent>
  );
};

export default EvolutionSousActiviteForm;
