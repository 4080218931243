import React from 'react'

const AbonnementAlert = ({value}) => {
  return (
    <div className={`container px-2 p-4 mt-4`}>
        <div 
            className={`
                container p-2 rounded-md 
                ${value.alert === 'info' && 'bg-sky-300 text-sky-950 border-sky-900 border-[1px]'}
                ${value.alert === 'essaie' && 'bg-sky-300 text-sky-950 border-sky-900 border-[1px]'}
                ${value.alert === 'error' && 'bg-red-200 text-red-950 border-red-600 border-[1px]'}
                ${value.alert === 'warning' && 'bg-amber-200 text-amber-950 border-amber-600 border-[1px]'}
            `}
        >
            <h3 className='font-bold'>{value.titre}</h3>
            <p className='font-normal'>{value.message}</p>
        </div>
    </div>
  )
}

export default AbonnementAlert