import React from 'react'
import Navigations from './Navigations'

const MainContent = ({children, title}) => {
  return (
    <div>
        <Navigations title={title}/>
        <div className='max-w-screen-xl mx-auto pt-24'>
            <div className='px-2 md:px-0'>
                {children}
            </div>
        </div>
    </div>
  )
}

export default MainContent