import axios from "axios";
import { getItem } from "../services";

const DEBUG = false;

// URL du back en local
const LOCAL_URL = "http://localhost:8000";

// URL du back en production
const PRODUCTION_URL = "https://back-office.sspp.cg";


export const BASE_URL = DEBUG ? LOCAL_URL : PRODUCTION_URL;

// Créer une instance Axios
export const Axios = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Intercepteur pour ajouter le token automatiquement
Axios.interceptors.request.use(
  async (config) => {

    const token = await getItem("keyStore");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepteur pour gérer les erreurs globales (ex: token expiré)
Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      console.warn("Token invalide ou expiré !");
      // Tu peux rediriger l'utilisateur vers la page de connexion ici
    }
    return Promise.reject(error);
  }
);

export const URL_PLATEFORM = '';

// Configuration de l'API Weather
export const openWeatherApi = {
    key: '28d242f717006cfcd5edce6775a2190b',
    base: 'https://api.openweathermap.org/data/2.5/'
}

export const newsApi = {
    key: '93196bb2c0364a388bb68b72dcae5940',
    base: 'https://restcountries.com/v3.1/'
}

