import React, { Fragment, useState } from "react";
import {
  EvolutionTimeLine,
  Input,
  LinkBtn,
  MainContent,
} from "../../../components";
import { useParams } from "react-router-dom";
import {
  AiOutlineEdit,
  AiOutlinePlus,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { usePlateformContext } from "../../../contexts";
import {
  check_admin,
  getElementData,
  obtenirPeriode,
} from "../../../constances";
import { exportToExcel, exportToPDF, normalizeString } from "../../../constances/functions";

const SousActiviteView = () => {
  const { id_unite_gestion } = useParams();
  const {
    composantes,
    sousComposantes,
    voletsActivites,
    user,
    sousActivites,
    categorieDepenses,
    activites,
  } = usePlateformContext();

  let model_data = {
    query: "",
  };

  const [credentials, setCredentials] = useState(model_data);

  let sous_activites_data = sousActivites
    .filter((item) => item.unite_gestion.toString() === id_unite_gestion)
    .filter(
      (item) =>
        normalizeString(item.designation).includes(
          `${credentials.query}`.toLowerCase()
        ) ||
        normalizeString(item.indicateur_resultat).includes(
          `${credentials.query}`.toLowerCase()
        )
    );

  let composantes_data = composantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let sousComposantes_data = sousComposantes.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );
  let voletsActivites_data = voletsActivites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <MainContent title={`Retour`}>
      {/* Entete */}
      <div className="flex items-center mt-5 justify-between px-2 md:px-1 py-4">
        <div className="xl:max-w-[70%] max-w-[50%]">
          <h1 className="font-bold text-lg">Sous-activités</h1>
          <p className="text-md font-light">
            Liste des sous-activités enregistrées pour le projet
          </p>
        </div>

        <div className="flex items-center">
          {check_admin(user) && (
            <LinkBtn
              description={"Nouvelle sous-activité"}
              icon={<AiOutlinePlusCircle />}
              path={`/unites-gestions/${id_unite_gestion}/sous-activites/nouvelle`}
              className={
                "bg-color-plt-1 text-light md:w-auto w-[100%] mb-2 md:mb-0"
              }
            />
          )}
        </div>
      </div>

      <div className="flex items-center justify-between w-full md:flex-row flex-col py-4">
        <Input
          type={"text"}
          placeholder={"Recherche ..."}
          value={credentials?.query}
          className={"md:w-[40%] w-[100%] mb-2"}
          onChange={handleChange}
          name={"query"}
        />

        <div className="flex space-x-2">
          <button
            onClick={() => exportToExcel(`SOUS-ACTIVITES`)}
            className="bg-green-500 text-white px-4 py-2 rounded text-sm"
          >
            Exporter en Excel
          </button>
          <button
            onClick={() => exportToPDF(`SOUS-ACTIVITES`)}
            className="bg-red-500 text-white px-4 py-2 rounded text-sm"
          >
            Exporter en PDF
          </button>
        </div>
      </div>

      {/* Tableau des composantes sous-composantes et volets d'activites */}
      <div className="container px-2 p-4">
        {/* Composantes */}
        <div className="table-container mb-10">
          <table className="table table-export-excel table-export-pdf">
            <thead className="bg-dark text-light">
              <tr>
                <th>N°</th>
                <th>Référence</th>
                <th>Designation</th>
                <th>Composante</th>
                <th>Sous-composante</th>
                <th>Volet d'activité</th>
                <th>Activité</th>
                <th>Periode d'execution</th>
                <th>Categorie de depense</th>
                <th>Nature</th>
                <th>Montant</th>
                <th>Status</th>
                {/* <th>Evolution</th> */}

                {check_admin(user) && <th></th>}
              </tr>
            </thead>
            <tbody>
              {sous_activites_data.map((item, index) => {
                return (
                  <tr index={index}>
                    <td>{index + 1}</td>
                    <td>{item.reference}</td>
                    <td className="whitespace-normal">{item.designation}</td>
                    <td className="whitespace-normal">
                      {
                        getElementData(item.composante, composantes_data)
                          ?.reference
                      }
                    </td>
                    <td className="whitespace-normal">
                      {
                        getElementData(
                          item.sous_composante,
                          sousComposantes_data
                        )?.reference
                      }
                    </td>
                    <td className="whitespace-normal">
                      {
                        getElementData(
                          item.volet_activite,
                          voletsActivites_data
                        )?.designation
                      }
                    </td>
                    <td className="whitespace-normal">
                      {getElementData(item.activite, activites)?.designation}
                    </td>
                    <td className="capitalize">
                      {obtenirPeriode(item.date_debut, item.date_fin)}
                    </td>
                    <td className="whitespace-normal">
                      {
                        getElementData(
                          item.categorie_depense,
                          categorieDepenses
                        )?.designation
                      }
                    </td>
                    <td className="whitespace-normal">{item.nature}</td>
                    <td className="whitespace-normal">{item.montant}</td>
                    <td className="">{item.status}</td>
                    {/* <td className="whitespace-normal">
                                      <EvolutionTimeLine value={70}/>  
                                    </td> */}

                    {check_admin(user) && (
                      <td className="w-[100px]">
                        <span className="flex">
                          <LinkBtn
                            description={"Details"}
                            icon={<AiOutlinePlus />}
                            className={"h-5"}
                            path={`/unites-gestions/${id_unite_gestion}/sous-activites/details/${item.id}`}
                          />

                          <LinkBtn
                            description={"Modifier"}
                            icon={<AiOutlineEdit />}
                            className={"h-5"}
                            path={`/unites-gestions/${id_unite_gestion}/sous-activites/${item.id}`}
                          />
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })}

              {!sous_activites_data.length > 0 && (
                <tr>
                  <td colSpan={12} className="h-20">
                    Aucune sous-activité
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MainContent>
  );
};

export default SousActiviteView;
