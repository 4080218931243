import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Textarea = ({
  className, 
  error, 
  label, 
  name, 
  placeholder, 
  required,
  value,
  onChange,
  onFocus = () => {},
  disabled=false,
  autoComplete='on',
}) => {

  return (
    <div className={`flex flex-col ${className} p-0`}>
        <label className='text-xs mb-1'>
          {label}
          {required && <span className="text-red-500 text-lg">*</span>}
        </label>

        <div className={`flex items-center border ${error ? 'border-red-500' : 'border-gray-300'}`}>
            <textarea 
                name={name}
                placeholder={placeholder}
                className='flex-grow outline-none border-0 px-2 py-2 font-normal min-h-16'
                value={value}
                onChange={onChange}
                onFocus={onFocus}
                disabled={disabled}
                autoComplete={autoComplete}
                rows={6}
            ></textarea>
        </div>

        {error && <span className='text-xs text-red-500'>{error}</span>}
    </div>
  )
}

export default Textarea;
