import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { IsLoading, PrivateRoot } from '../components'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AbonnementsView, ActiviteForm, ActiviteInicateurForm, ActivitePlanActionForm, ActiviteRessourceBudgetForm, ActiviteResultatForm, ActiviteTacheForm, ActiviteView, CategorieDepenseForm, CategorieDepenseView, ComposanteForm, ComposantesView, DemandeANO, EmailView, EvolutionSousActiviteForm, EvolutionTacheForm, FeuilleDeRouteForm, FeuilleDeRouteView, HomeView, IndicateurForm, IndicateursView, LoginView, NotificationsView, OTPView, PasswordView, PlanActionForm, PlansActionsView, ProfileView, PtbaDetailsView, PtbaForm, PtbaView, ReportingView, SousActiviteDetails, SousActiviteForm, SousActiviteView, SousComposanteForm, SousComposanteView, SuiviANOView, TacheDetailsView, UniteGestionForm, UniteGestionView, UtilisateurForm, UtilisateursView, VoletActiviteForm, VoletActiviteView } from '../views';
import { usePlateformContext } from '../contexts';

const RouterNavigation = () => {

  const { isLoading } = usePlateformContext();

  return (
    <>
      <ToastContainer
        position='bottom-right'

        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"

        style={{
          fontSize: 13,
          lineHeight: 1.3
        }}
      />

      <IsLoading visible={isLoading} />

      <Routes>
        <Route exact path={`/login`} element={<LoginView />} />
        <Route exact path={`/email-send-informations`} element={<EmailView />} />
        <Route exact path={`/otp`} element={<OTPView />} />

        <Route path={`/`} element={<PrivateRoot />}>
          <Route exact path={`/`} element={<HomeView />} />
        </Route>

        {/* Profile */}
        <Route exact path={`/change-password`} element={<PasswordView />} />

        <Route path={`/profile`} element={<PrivateRoot />}>
          <Route exact path={`/profile`} element={<ProfileView />} />
        </Route>

        {/* Abonnements */}
        <Route path={`/abonnements-liste`} element={<PrivateRoot />}>
          <Route exact path={`/abonnements-liste`} element={<AbonnementsView />} />
        </Route>

        {/* Unite de gestion */}
        <Route path={`/unites-gestion-form/:id_unite_gestion`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestion-form/:id_unite_gestion`} element={<UniteGestionForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion`} element={<UniteGestionView />} />
        </Route>

        {/* Composantes sous composantes et volets d'activites */}
        <Route path={`/unites-gestions/:id_unite_gestion/composantes`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/composantes`} element={<ComposantesView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/composantes/:id_composante`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/composantes/:id_composante`} element={<ComposanteForm />} />
        </Route>

        {/* Sous composante */}
        <Route path={`/unites-gestions/:id_unite_gestion/sous-composantes`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-composantes`} element={<SousComposanteView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/sous-composantes/:id_sous_composante`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-composantes/:id_sous_composante`} element={<SousComposanteForm />} />
        </Route>

        {/* Volet d'activites */}
        <Route path={`/unites-gestions/:id_unite_gestion/volet-activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/volet-activite`} element={<VoletActiviteView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/volet-activite/:id_volet_activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/volet-activite/:id_volet_activite`} element={<VoletActiviteForm />} />
        </Route>

        {/* Indicateurs */}
        <Route path={`/unites-gestions/:id_unite_gestion/indicateurs`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/indicateurs`} element={<IndicateursView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/indicateurs/:id_indicateur`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/indicateurs/:id_indicateur`} element={<IndicateurForm />} />
        </Route>

        {/* Plan d'action */}
        <Route path={`/unites-gestions/:id_unite_gestion/plans-actions`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/plans-actions`} element={<FeuilleDeRouteView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route`} element={<FeuilleDeRouteForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/plans-actions/:id_feuille_de_route`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/plans-actions/:id_feuille_de_route`} element={<PlansActionsView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route/activite/:id_activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route/activite/:id_activite`} element={<ActivitePlanActionForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route/actions/:id_plan_action`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/plans-actions/feuille-de-route/:id_feuille_de_route/actions/:id_plan_action`} element={<PlanActionForm />} />
        </Route>

        {/* PTBA */}
        <Route path={`/unites-gestions/:id_unite_gestion/ptba`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/ptba`} element={<PtbaView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/ptba/:id_ptba`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/ptba/:id_ptba`} element={<PtbaForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/ptba/:id_ptba/details`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/ptba/:id_ptba/details`} element={<PtbaDetailsView />} />
        </Route>

        {/* Activites */}
        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite`} element={<ActiviteForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/activites`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites`} element={<ActiviteView />} />
        </Route>


        {/* Sous activites */}
        <Route path={`/unites-gestions/:id_unite_gestion/sous-activites/:id_sous_activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-activites/:id_sous_activite`} element={<SousActiviteForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/sous-activites`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-activites`} element={<SousActiviteView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/sous-activites/details/:id_sous_activite`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-activites/details/:id_sous_activite`} element={<SousActiviteDetails />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/sous-activites/details/:id_sous_activite/evolution/:id_evolution`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/sous-activites/details/:id_sous_activite/evolution/:id_evolution`} element={<EvolutionSousActiviteForm />} />
        </Route>

        {/* Indicateurs de la t'activite */}

        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/indicateurs/ajouter`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/indicateurs/ajouter`} element={<ActiviteInicateurForm />} />
        </Route>

        {/* Resultat attendu de l'activite */}
        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/resultat-attendu/:id_resultat`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/resultat-attendu/:id_resultat`} element={<ActiviteResultatForm />} />
        </Route>

        {/* tache de l'activite */}
        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/:id_tache`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/:id_tache`} element={<ActiviteTacheForm />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/details/:id_tache`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/details/:id_tache`} element={<TacheDetailsView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/details/:id_tache/evolution/:id_evolution`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/taches/details/:id_tache/evolution/:id_evolution`} element={<EvolutionTacheForm />} />
        </Route>

        {/* Ressources budget pour une tache de l'activite */}
        <Route path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/ressource-budget/:id_ressource`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/activites/:id_activite/ressource-budget/:id_ressource`} element={<ActiviteRessourceBudgetForm />} />
        </Route>

        {/* Categorie de depenses */}
        <Route path={`/unites-gestions/:id_unite_gestion/categorie-depenses`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/categorie-depenses`} element={<CategorieDepenseView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/categorie-depenses/:id_categorie_depenses`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/categorie-depenses/:id_categorie_depenses`} element={<CategorieDepenseForm />} />
        </Route>

        {/* Reporting activite */}
        <Route path={`/unites-gestions/:id_unite_gestion/reporting`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/reporting`} element={<ReportingView />} />
        </Route>

        {/* Gestion utilisateurs */}
        <Route path={`/unites-gestions/:id_unite_gestion/utilisateurs`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/utilisateurs`} element={<UtilisateursView />} />
        </Route>

        <Route path={`/unites-gestions/:id_unite_gestion/utilisateurs/:id_utilisateur`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/utilisateurs/:id_utilisateur`} element={<UtilisateurForm />} />
        </Route>


        {/* Notifications */}
        <Route path={`/unites-gestions/:id_unite_gestion/notifications`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/notifications`} element={<NotificationsView />} />
        </Route>

        {/* Suivi des ano */}
        <Route path={`/unites-gestions/:id_unite_gestion/suivi-ano`} element={<PrivateRoot />}>
          <Route exact path={`/unites-gestions/:id_unite_gestion/suivi-ano`} element={<SuiviANOView />} />
          <Route exact path={`/unites-gestions/:id_unite_gestion/suivi-ano/:id_demande_ano`} element={<DemandeANO />} />
        </Route>

      </Routes>
    </>
  )
}

export default RouterNavigation