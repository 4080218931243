import React, { useState } from "react";
import { Input, LinkBtn, MainContent, Select } from "../../../components";
import { useParams } from "react-router-dom";
import { usePlateformContext } from "../../../contexts";
import { AiOutlineEdit, AiOutlinePlusCircle } from "react-icons/ai";
import {
  check_admin_composante,
  exportToExcel,
  exportToPDF,
  formaterDate,
  normalizeString,
} from "../../../constances/functions";
import SearchableSelect from "../../../components/ui/SearchableSelect";

const SuiviANOView = () => {
  const { id_unite_gestion } = useParams();
  const { demandesANO, user, activites } = usePlateformContext();

  // let unite_gestion = getElementData(id_unite_gestion, unites_gestions);
  let activites_data = activites.filter(
    (item) => item.unitegestion.toString() === id_unite_gestion
  );

  // ===================================

  let model_data = {
    query: "",
    activite: "",
  };

  const [credentials, setCredentials] = useState(model_data);

  let filterDemandesANO = demandesANO
    .filter((item) => item.unitegestion.toString() === id_unite_gestion)
    .filter((item) => {
      const filterActivites = !credentials.activite
        ? true
        : item.activite === parseInt(credentials.activite);

      return (
        normalizeString(item.designation).includes(
          `${credentials.query}`.toLowerCase()
        ) && filterActivites
      );
    });

  const handleChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  return (
    <MainContent title={`Programme de Travail et Budget Annuel (PTBA)`}>
      {/* Entete */}
      <div className="flex items-center mt-5 justify-between px-2 md:px-1 py-4">
        <div className="xl:max-w-[70%] max-w-[50%]">
          <h1 className="font-bold text-lg">Suivi des demandes d'ANO</h1>
          <p className="text-md font-light">
            Tableau de suivi des demandes d'avis de non-objection
          </p>
        </div>

        <div className="flex items-center">
          {check_admin_composante(user, user?.composantes) && (
            <LinkBtn
              description={"Nouvelle demande"}
              icon={<AiOutlinePlusCircle />}
              path={`/unites-gestions/${id_unite_gestion}/suivi-ano/nouvelle`}
              className={
                "bg-color-plt-1 text-light md:w-auto w-[100%] mb-2 md:mb-0"
              }
            />
          )}
        </div>
      </div>

      <div className="flex items-center justify-between w-full md:flex-row flex-col py-4">
        <div className="flex md:items-center md:w-[40%] w-[100%] md:flex-row flex-col mb-2">
          {/* <Select
            type={"text"}
            required={false}
            placeholder={"Toutes les sous activité"}
            autoComplete="off"
            value={credentials?.activite}
            onChange={handleChange}
            name={"activite"}
            options={activites_data.map((a) => ({
              value: a.id,
              label: `${a.reference} - ${a.designation}`,
            }))}
            className={"md:w-[50%] w-[100%]"}
          /> */}

          <SearchableSelect
            options={activites_data.map((a) => ({
              value: a.id,
              label: `${a.reference} - ${a.designation}`,
            }))}
            value={credentials?.activite}
            placeholder="Sélectionnez une activité"
            onChange={(e) =>
              handleChange({ target: { name: "activite", value: e.value } })
            }
            className="md:w-[50%]"
          />

          <Input
            type={"text"}
            placeholder={"Recherche ..."}
            value={credentials?.query}
            className={"md:w-[50%] w-[100%]"}
            onChange={handleChange}
            name={"query"}
          />
        </div>

        <div className="flex space-x-2 md:mt-0 mt-5">
          <button
            onClick={() => exportToExcel(`Demandes d'avis de non-objection`)}
            className="bg-green-500 text-white px-4 py-2 rounded text-sm"
          >
            Exporter en Excel
          </button>
          <button
            onClick={() => exportToPDF(`Demandes d'avis de non-objection`)}
            className="bg-red-500 text-white px-4 py-2 rounded text-sm"
          >
            Exporter en PDF
          </button>
        </div>
      </div>

      {/* Tableau des composantes sous-composantes et volets d'activites */}
      <div className="container px-2 p-4">
        {/* Composantes */}
        <div className="table-container mb-10">
          <table className="table table-export-excel table-export-pdf">
            <thead className="bg-dark text-light">
              <th>Réf</th>
              <th>Activité</th>
              <th>Description</th>
              <th>Date de transmission</th>
              <th>Date probable</th>
              <th>Date réel</th>
              <th>Nbre de jours</th>
              <th>Statut</th>
            </thead>
            <tbody>
              {filterDemandesANO.map((item, index) => {
                const activite = activites_data.find(
                  (a) => a.id === item.activite
                );

                // Convertir les dates en objets Date
                const dateTransmission = new Date(item.date_transmission);
                const dateReel = item.date_reel
                  ? new Date(item.date_reel)
                  : null;

                // Date probable = Date de transmission + 15 jours
                const dateProbable = new Date(dateTransmission);
                dateProbable.setDate(dateProbable.getDate() + 15);

                // Nombre de jours entre date_transmission et date_reel (si disponible)
                const nb_jour_reel = dateReel
                  ? Math.floor(
                      (dateReel - dateTransmission) / (1000 * 60 * 60 * 24)
                    )
                  : null;

                // Détermination du statut
                let statut = "En attente";
                const aujourdHui = new Date();

                let color = "";

                if (!dateReel) {
                  statut =
                    dateProbable <= aujourdHui
                      ? "En retard"
                      : "Dans les délais";
                } else {
                  statut =
                    dateReel <= dateProbable ? "Dans les délais" : "En retard";
                }

                if (statut === "En retard" && !dateReel) {
                  color = "#ff0000";
                }

                return (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: color ? color + "10" : "transparent",
                    }}
                  >
                    <td className="w-[50px]">{item.reference}</td>
                    <td className="whitespace-normal">
                      {activite?.designation || "-"}
                    </td>
                    <td className="whitespace-normal">{item.designation}</td>
                    <td>{formaterDate(item.date_transmission)}</td>
                    <td>{formaterDate(dateProbable)}</td>
                    <td className="text-center">
                      {item.date_reel ? formaterDate(item.date_reel) : "-"}
                    </td>
                    <td className="whitespace-normal">{nb_jour_reel ?? "-"}</td>
                    <td className="whitespace-normal">{statut}</td>

                    {check_admin_composante(user, user?.composantes) && (
                      <td className="w-[90px]">
                        <span className="flex">
                          <LinkBtn
                            description={"Modifier"}
                            icon={<AiOutlineEdit />}
                            className={"h-5"}
                            path={`/unites-gestions/${id_unite_gestion}/suivi-ano/${item.id}`}
                          />
                        </span>
                      </td>
                    )}
                  </tr>
                );
              })}

              {!filterDemandesANO.length > 0 && (
                <tr>
                  <td colSpan={8} className="h-20 text-center">
                    Aucune demande d'avis de non-objection
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </MainContent>
  );
};

export default SuiviANOView;
